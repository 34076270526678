#light footer {
  background-color: $grey1;
  color: $grey2;

  .copyright {
    span {
      color: $violet;
    }
  }
  .btnPhone {
  padding: 5px 10px;
  border-radius: 5px;
  color: $white2;
  background: $violet;
  border: solid 1px $violet;
  &:hover{
    color: $violet;
    background: $white2;
  }
}
}

footer {
  display: flex;
  align-items: center;
  position: relative;
  padding: 30px;
  justify-content: space-between;
  background-color: $black3;
  color: $white2;
  min-height: 200px;
  .contact-infos {
    font-size: 0.9rem;
    min-height: 70px;
  }
}

.copyright {
  min-width: 360px;
  font-size: 0.7rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 30px 0;
  text-align: center;
  span {
    color: $turquoise;
  }
}

.btnPhone {
  padding: 5px 10px;
  border-radius: 5px;
  color: $turquoise;
  background: $black3;
  border: solid 1px $turquoise;
  &:hover{
    color: $white2;
    background: $turquoise;
  }
}

//media queries
@media screen and (max-width: 650px) {
  footer {
    flex-direction: column-reverse;
    .contact-infos {
      padding: 30px 0 50px 0;
      min-height: 150px;
    }

  }
}
