@font-face {
  font-family: 'openSans-700';
  src: url(../assets/fonts/OpenSans-Bold.ttf);
}
@font-face {
  font-family: 'openSans-400';
  src: url(../assets/fonts/OpenSans-Regular.ttf);
}
@font-face {
  font-family: 'openSans-300';
  src: url(../assets/fonts/OpenSans-Light.ttf);
}

$font700: 'openSans-700', Arial, sans-serif;
$font400: 'openSans-400', Arial, sans-serif;
$font300: 'openSans-300', Arial, sans-serif;

$blue: #00009c;
$turquoise: #00b7c7;
$violet: rgb(120, 67, 233);
$white1: rgb(255, 255, 255);
$white2: rgb(251, 251, 251);
$black1: rgb(0, 0, 0);
$black2: rgb(17, 17, 17);
$black3: rgb(40, 40, 40);
$grey1: rgb(231, 231, 231);
$grey2: rgb(102, 102, 102);

:export {
  blue: $blue;
  violet: $violet;
  white1: $white1;
  white2: $white2;
  black1: $black1;
  black2: $black2;
  black3: $black3;
  grey1: $grey1;
  grey2: $grey2;
  font700: $font700;
  font400: $font400;
  font300: $font300;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: $white1;
  font-family: $font400;
  font-weight: 400;
  color: $grey2;
  &:has(#black) {
    background: $black3;
  }
}

html {
  scroll-behavior: smooth;
}

li {
  list-style: none;
}

a,
a:visited {
  text-decoration: none;
}

.animeOpen {
  -webkit-animation-name: animeOpen;
  animation-name: animeOpen;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.tilt-in-left-1 {
  -webkit-animation: tilt-in-left-1 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: tilt-in-left-1 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slit-out-vertical {
  -webkit-animation: slit-out-vertical 0.5s ease-in both;
  animation: slit-out-vertical 0.5s ease-in both;
}
//buttons & links as btn

.button {
  padding: 5px 10px;
  background: $black3;
  border: 2px solid $turquoise;
  border-radius: 3px;
  margin: 0 5px;
  color: $turquoise;
  font-family: $font700;
  font-size: 1rem;
  text-transform: uppercase;
  transition: all 0.3s ease;
  &:hover,
  &:focus-visible {
    border: 2px solid $turquoise;
    background: $turquoise;
    color: $white2;
  }
  &:focus-visible {
    outline: 1px solid $turquoise;
  }
}

#light .button {
  background: $violet;
  border: 2px solid $violet;
  color: $white2;
  &:hover,
  &:focus-visible {
    border: 2px solid $grey1;
    background: $grey1;
    color: $violet;
  }
  &:focus-visible {
    outline: 1px solid $white2;
  }
}
