.toggle-btn {
  position: fixed;
  top: 13px;
  right: 100px;
  cursor: pointer;
  height: 45px;
  width: 45px;
  z-index: 10;
}

.toggle-btn span {
  width: 45px;
  height: 5px;
  border-radius: 5px;
  background: $white2;
  display: block;
  margin-top: 7px;
  transition: 0.3s ease;
}

#sidebar {
  position: absolute;
  width: 100%;
  height: 0;
  left: 0px;
  top: 70px;
  transition: 0.5s ease-out;
  z-index: -1;
  ul {
    flex-direction: column;
    background: $black3;
    height: 0;
    padding: 0;
    transition: 0.5s ease-in-out;
    a {
      height: 0;
      opacity: 0;
    }
    li:hover,
    li:focus,
    li:active {
      background-color: $turquoise;
    }
    .isActive {
      background-color: $turquoise;
      &:hover,
      &:focus,
      &:active {
        background-color: $turquoise;
      }
    }
  }
  .menu {
    position: absolute;
    font-size: 0.8rem;
    min-width: 45px;
    right: 100px;
    bottom: 3px;
    text-align: center;
  }
}


#light #sidebar {
  ul {
    li:hover,
    li:focus,
    li:active {
      background-color: $violet;
    }
    .isActive {
      background-color: $violet;
      &:hover,
      &:focus,
      &:active {
        background-color: $violet;
      }
    }
  }
}

/* code ajouté */
.hidden span {
  position: absolute;
  bottom: 21px;
}
.hidden span:first-child {
  transform: rotate(45deg);
}
.hidden span:nth-child(2) {
  opacity: 0;
}
.hidden span:last-child {
  transform: rotate(-45deg);
}
#sidebar.hidden ul {
  opacity: 1;
  height: 259px;
  a {
    width: 100%;
    height: auto;
    opacity: 1;
    transition: 0.5s ease-in-out 0.25s;
    text-align: center;
  }
}
#sidebar.hidden {
  top: 75px !important;
  z-index: 4;
}

#light #sidebar {
  .toggle-btn span {
    background: $grey2;
  }
  ul {
    background: $white2;
  }
  li:hover {
    background-color: $violet;
    color: $white2;
  }
}
