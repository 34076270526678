#light .social-network {
  a {
    transition: 0.7s ease-in-out;
    color: $grey2;
    &:hover {
      color: $violet;
    }
  }
}

.social-network {
  min-width: 200px;
  max-width: 250px;
  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  a {
    transition: 0.7s ease-in-out;
    &:hover {
      color: $turquoise;
    }
  }
  i {
    font-size: 2rem;
  }
}

#dark header .social-network {
  a {
    transition: 0.7s ease-in-out;
    &:hover {
      color: $turquoise;
    }
  }
}


header .social-network {
  position: absolute;
  right: 5px;
  min-width: 50px;
  padding-right: 15px;
  .content {
    a {
      &:hover {
        color: $turquoise;
      }
    }
  }
}

#light header .social-network {
  .content {
    a {
      &:hover {
        color: $violet;
      }
    }
  }
}