#light #work {
  background: $white2;
  color: $grey2;
}
#dark #work {
  background: $black3;
  color: $white2;
}

#work {
  h2 {
    text-align: center;
    margin: 0 auto;
    padding: 120px 0 60px;
  }
  // p {
  //   text-align: center;
  //   margin: 0 auto;
  //   padding: 0 0 60px;
  //   font-size: 1.5rem;
  // }
}

.work-container {
  overflow: hidden;
  opacity: 0;
}
