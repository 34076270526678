/* Custom Select wrapper */
select {
  /* Reset Select */
  appearance: none;
  outline: 10px red;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 1em;
  background-image: none;
  cursor: pointer;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
.select-container {
  position: relative;
  z-index: 10;
  display: flex;
  width: 100px;
  height: 35px;
  border-radius: 0.25em;
  overflow: hidden;
  img{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 40%;
    z-index: 2;
    width: 18%;
    height: auto;
  }
}
/* Arrow */
.select-container::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px 15px 8px;
  transition: 0.25s all ease;
  pointer-events: none;
}

#light {
  select {
    color: $grey2;
    background-color: $grey1;
  }
  .select-container::after {
    background-color: $grey1;
  }
  .select-container:hover::after {
    color: $violet;
  }
}
#dark {
  select {
    color: $white2;
    background-color: $black3;
  }
  .select-container::after {
    background-color: $black3;
  }
  .select-container:hover::after {
    color: $turquoise;
  }
}
