#light #intro {
  background: linear-gradient(to right, rgba($grey2, 0.2), rgba($grey1, 0.5)),
    url(../../../assets/bg-white.png);
  background-size: cover;
  h1,
  h2 {
    color: $black3;
  }

  .intro_wrapper:after {
    border: solid $black3 2px;
  }

  a {
    color: $black3;
  }
}
#dark #intro {
  background: linear-gradient(to right, rgba($grey1, 0.2), rgba($grey2, 0.5)),
    url(../../../assets/bg-black.png);
  background-size: cover;
  h1,
  h2 {
    color: $white2;
  }

  h1:after {
    border: solid $white2 2px;
  }

  .intro_wrapper:after {
    border: solid $white2 2px;
  }
}
a {
  color: $white2;
}

#intro {
  position: relative;
  height: 100vh;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .intro_wrapper {
    position: relative;
    max-width: 80%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:after {
      position: absolute;
      content: '';
      bottom: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%) scaleX(0);
      transform-origin: center;
      opacity: 0;
      animation-name: revealSpan;
      animation-duration: 1.5s;
      animation-delay: 0.1s;
      animation-fill-mode: both;
    }
  }

  h1 {
    flex: 0 0 50%;
    min-height: 124px;
    font-size: 2.5rem;
    font-family: $font700;
    padding: 20px 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    vertical-align: middle;
    position: relative;
    opacity: 0;
    animation-name: revealUp;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    display: flex;
    align-items: flex-end;
    span {
      display: block;
    }
  }

  h2 {
    flex: 0 0 50%;
    font-size: 1.2rem;
    min-height: 124px;
    font-family: $font300;
    display: flex;
    margin: 0;
    padding: 20px 0;
    flex-direction: column;
    align-items: center;
    line-height: 1.6;
    opacity: 0;
    animation-name: revealDown;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  a {
    position: absolute;
    bottom: 75px;
    width: 60px;
    text-align: center;
    animation: wobbleAnim 2s ease 0.5s infinite normal forwards;
    &:hover:has(.logoAnim) {
      animation: none;
    }
    img {
      position: relative;
      z-index: 2;
      width: 25px;
      height: 25px;
      transition: all 0.3s ease-in-out;
      &:hover {
        opacity: 0;
        transform: rotate(-360deg);
      }
      &:hover ~ .chevron {
        opacity: 1;
      }
    }

    .logoAnim {
      font-size: 1rem;
    }

    .chevron {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 120px;
      bottom: -10px;
      display: flex;
      flex-direction: column;
      opacity: 0;
    }
  }

  //media queries
  @media screen and (max-width: 895px) {
    h1 {
      font-size: 2.5rem;
    }
  }

  @media screen and (max-width: 652px) {
    .intro_wrapper {
      max-width: 85%;
    }
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.1rem;
    }
  }

  @media screen and (max-width: 550px) {
    .intro_wrapper {
      max-width: 95%;
    }
    h2 {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 450px) {
    h2 {
      font-size: 0.8rem;
    }
  }
}
