#dark #skills {
  color: $white2;
  background: linear-gradient(to bottom, rgba($black1, 0.5), rgba($black3, 0.4)),
    url(https://i.imgur.com/QZki6Dy.jpg) bottom center / cover fixed;

  .skills-content {
    ul {
      background: rgba($white2, 0.2);
      padding: 20px;
      border-radius: 10px;
    }
  }
  .skills-main {
    background: rgba($white2, 0.2);
    .skills-main-card {
      color: $white2;
      span{
        background: $turquoise;
      }
    }
  }

  .skills-logo-container {
    img {
      filter: grayscale(30%) sepia(10%) brightness(55%);
      transition: all 0.2s ease-in-out;
      &:hover {
        filter: none;
      }
    }
  }
}
#light #skills {
  color: $black3;
  background: linear-gradient(to bottom, rgba($white1, 0.5), rgba($white2, 0.8)),
    transparent url(../../../assets/img/computer-light.jpg) bottom center /
      cover fixed;

  .skills-content {
    ul {
      background: rgba($grey2, 0.3);
      padding: 20px;
      border-radius: 10px;
      font-weight: 500;
    }
  }

    .skills-main {
    background: rgba($grey2, 0.3);
    .skills-main-card {
      color: $black3;
      span{
        color: $white2;
        background: $violet;
      }
    }
  }

  .skills-logo-container {
    img {
      filter: grayscale(100%);
      transition: all 0.2s ease-in-out;
      &:hover {
        filter: none;
      }
    }
  }

  .skills-container{
      .button {
  &:hover,
  &:focus-visible {
    border: 2px solid $violet;
    background-color: $white2;
  }
  &:focus-visible {
    outline: 1px solid $violet;
  }
}
  }
}

#skills {
  min-height: 500px;
  background-color: $grey1;
  padding: 120px 0;
  

  h2 {
    margin: 0;
  }
}

.skills-container {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .skill-desc {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 1.5rem;
    text-align: center;
    line-height: 32px;
    padding: 40px 0;
    margin: 0 20px;
  }

  .button {
    margin-top: 20px;
  padding: 10px 30px;
  background: $turquoise;
  border: 2px solid $turquoise;
  color: $white2;
  font-family: $font700;
  font-size: 1rem;
  text-transform: uppercase;
  transition: all 0.3s ease;
  &:hover,
  &:focus-visible {
    border: 2px solid $turquoise;
    background: $black3;
    color: $turquoise;
  }
  &:focus-visible {
    outline: 1px solid $turquoise;
  }
}

}

.skills-main {
  max-width: 75%;
  padding: 30px 20px 20px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}
.skills-main-card {
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  span {
    display: block;
    height: 60px;
    width: 60px;
    background: $turquoise;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    border-radius: 40px;
  }
  p {
    padding: 10px 5px;
    text-align: center;
  }
}

.skills-content {
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-width: 350px;
  width: 100%;
  max-width: 900px;
  p {
    text-align: center;
    margin: 30px 0 30px;
    text-transform: uppercase;
    font-size: 1.2rem;
  }
}

.skills-logo-section {
  flex: 0 0 60%;
}

.skills-logo-container {
  ul {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  li {
    width: 96px;
    height: 120px;
    padding: 0 15px 15px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
  }
  .bg {
    background: $white2;
    border-radius: 5px;
    padding: 5px;
  }
}

.skills-other-section {
  flex: 0 0 40%;
  span {
    margin-left: 15px;
  }
}

.skills-other-list-card {
  max-width: 300px;
  margin: 0 auto;

  p {
    text-align: start;
  }

  ul {
    li {
      width: 100%;
    }
  }
}

//media queries
@media screen and (max-width: 850px) {
  .skills-content {
    flex-direction: column;
  }
}
