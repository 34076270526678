//h1 animation
@keyframes revealUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes revealDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes revealSpan {
  20% {
    opacity: 1;
    transform: translateX(-50%) scaleX(1);
  }

  80% {
    opacity: 1;
    transform: translateX(-50%) scaleX(1);
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) scaleX(0);
  }
}

// section animation
@keyframes animeOpen {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// aniation for the darkmode button
@keyframes darkMode {
  0% {
    transform: translateX(0);
  }

  70% {
    transform: translateX(33px);
  }
  85% {
    transform: translateX(29px);
  }
  100% {
    transform: translateX(33px);
  }
}

@keyframes lightMode {
  0% {
    transform: translateX(33px);
  }

  70% {
    transform: translateX(0px);
  }
  85% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(-2px);
  }
}

//animation success error form message
@keyframes tilt-in-left-1 {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
    transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
    transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}

@keyframes slit-out-vertical {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
}

//animation logo DG wobble(osciller)
@keyframes wobbleAnim {
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }

  15% {
    transform: translateX(-10px) rotate(-6deg);
  }

  30% {
    transform: translateX(10px) rotate(6deg);
  }

  45% {
    transform: translateX(-15px) rotate(-3.6deg);
  }

  60% {
    transform: translateX(7px) rotate(2.4deg);
  }

  75% {
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
