#light header {
  background-color: $white2;
  box-shadow: -12px 2px 20px 0px $grey2;
  a {
    color: $grey2;
  }
  nav {
    ul {
      li {
        &:hover {
          color: $violet;
          transform: scale(1.2);
        }
        &:active {
          color: $violet;
          transform: scale(1.4);
        }
      }
    }
  }
}
#dark header {
  background-color: $black2;
  box-shadow: -12px 2px 20px 0px $grey2;
  color: $white2;
  a {
    color: $white2;
  }
}
header {
  width: 100%;
  position: fixed;
  z-index: 10;
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    display: flex;
    gap: 15px;
    padding: 20px 10px;
    z-index: 10;
  }
  img {
    height: 25px;
    width: 27px;
  }

  .select-container {
    position: absolute;
    left: 200px;
  }

  button {
    height: 40px;
  }

  nav {
    ul {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 10px;
      padding-right: 100px;
      li {
        padding: 15px;
        &:hover {
          color: $turquoise;
          transform: scale(1.2);
        }
        &:active {
          color: $turquoise;
          transform: scale(1.4);
        }
      }
    }
  }
}

// media queries
@media screen and (max-width: 870px) {
  header {
    .logo {
      p {
        display: none;
      }
    }
    .select-container {
      position: absolute;
      left: 60px;
    }
     nav {
    ul {
      li {
        padding: 15px;
        &:hover,&:active {
          color: $white2;
          transform: none;
        }
      }
    }
}
  }
}
