#light #about {
  background-color: $white2;
  strong {
    color: $violet;
  }

  .about-soft-content {
    color: $violet;
  }
}

#dark #about {
  background-color: $black3;
  color: $grey1;
  strong {
    color: $turquoise;
  }
  .about-soft-content {
    color: $turquoise;
  }

  .about-soft,
  .about-work {
    background-color: lighten($black3, 10);
    p {
      color: $white2;
    }
  }
}

#about {
  min-height: 500px;
  display: flex;

  strong {
    font-size: 1.2rem;
  }

  .about-container {
    width: 100%;
    opacity: 0;
  }
  .wrapper {
    margin: 60px auto 120px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 650px;
    text-align: center;
  }
    //media queries
  @media screen and (max-width: 700px) {
    .about-description {
      padding: 0 15px;
    }
  }
}
h2 {
  text-transform: uppercase;
  margin: 0 0 40px 0;
  font-size: 2rem;
  font-family: $font300;
}

.about-profil {
  margin: 30px 0;
  img {
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 200px;
    border-radius: 100px;
  }
}

.about-description {
  text-align: left;
  margin: 25px 0;
  p {
    padding: 10px 0;
    line-height: 30px;
  }
}
.about-soft,
.about-work {
  background-color: $grey1;
  border-radius: 5px;
  padding: 25px;
  p:first-child {
    margin-bottom: 30px;
    font-size: 1.3rem;
    color: $grey2;
  }
}

.about-work {
  margin-top: 60px;
}

.about-soft-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: $violet;
  span {
    display: block;
    font-size: 45px;
    margin: 20px 0;
    width: 100%;
  }
}

.about-soft-card {
  width: 150px;
}

.about-logo-container {
  ul {
    margin: 0 auto;
    max-width: 500px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    justify-content: center;
  }
  li {
    min-width: 100px;
    max-width: 200px;
    height: auto;
    padding: 0 15px 15px 15px;
  }

  img {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
  }
  .bg {
    background: $white2;
  }
}
